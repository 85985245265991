import React from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import NavLink from "../atoms/NavLink";
import Logo from "../atoms/Logo";
import ActionButton from "../atoms/ActionButton";
import NoviroLogo from "../assets/Noviro.png";
import Jockey from "../assets/jockey_logo.7bd034785781aafa58ae.png";
const DesktopNav = ({ isAuthenticated, onLogoClick, onButtonClick }) => (
  <AppBar
    position="fixed"
    sx={{
      height: "10%",
      boxShadow: "none",
      zIndex: 1300,
      background: "rgb(221 195 164)",
    }}
  >
    <Toolbar
      disableGutters
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        height: "100%",
        gap: "5%",
        maxWidth: "100%",
        padding: "0 2%",
      }}
    >
      {/* Navigation Links */}
      <div
        style={{
          display: "flex",
          // gap: "7%",
          width: "20%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <NavLink to="/home" label="HOME" />
        <NavLink to="/chat" label="CHAT" />
        <NavLink to="/screens" label="SCREENS" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          flex: 1, // Allow it to take available space
        }}
      >
        <img src={Jockey} alt="Jockey Logo" width={"150px"} />
      </div>

      {/* Login/Logout Button */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "25%",
        }}
      >
        <img src={NoviroLogo} alt="Noviro Logo" width={"100px"} />
        <ActionButton
          label={isAuthenticated ? "LOGOUT" : "LOGIN"}
          onClick={onButtonClick}
        />
      </div>
    </Toolbar>
  </AppBar>
);

export default DesktopNav;
